<template>
  <v-card min-height="100vh" color="transparent" flat class="xw-full">
    <v-card-title class="xflex xflex-row xjustify-between xitems-center">
      <Filters
        :search="search"
        :value="selectedRange"
        @change="navigate" 
        @project-users-updated="fetch(selectedRange)"
        type="weekly"
      >
      </Filters>
    </v-card-title>
    <v-card-text>
      <UsersTable
        :loading="loading"
        :loading-more="loading_more"
        :items="item ? item.summary : []"
      >
      </UsersTable>
      <!-- <pre>{{ item.summary }}</pre> -->
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";
import Filters from "../components/Filters.vue";
import UsersTable from "../components/UsersTable.vue";
export default {
  data() {
    return {
      tab: "projects",
      loading: false,
      loading_more: false,
      item: null,
      search: null,
      selectedRange: [
        moment().startOf("week").format("YYYY-MM-DD"),
        moment().endOf("week").format("YYYY-MM-DD"),
      ],
      defaultTab: null,
    };
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        this.selectedRange = val
          ? [val.start, val.end]
          : [
              moment().startOf("week").format("YYYY-MM-DD"),
              moment().endOf("week").format("YYYY-MM-DD"),
            ];
        val && this.fetch(val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]), 
    projectId() {
      return this.$route.params.project_id || null;
    },
  },
  methods: {
    ...mapMutations("projects", ["storeProject"]),
    navigate(val) {
      this.$router
        .push({
          name: "app-project-weekly",
          params: { start: val[0], end: val[1], project_id: this.projectId },
        })
        .catch(() => {});
    },
    fetch(range) {
      this.loading = true;
      this.$axios
        .get(
          `api/project-users/${this.projectId}/weekly/${range.start}/${range.end}`
        )
        .then(({ data }) => {
          this.item = data;
          this.storeProject(data);
          this.$event.$emit(
            "project-count",
            this.item ? this.item.summary.length : 0
          );
        })
        .finally(() => (this.loading = false));
    },
  },
  components: { Filters, UsersTable },
};
</script>

<style lang="scss" scoped></style>
